import { CmsBusinessMaturityOption, CmsSignupIntentOption } from 'signup-constants/signupData/CmsOption';
import { CompanySizeOption } from 'signup-constants/signupData/CompanySizeOption';
import { CrmExperienceOption } from 'signup-constants/signupData/CrmExperienceOption';
import { HubInterestOption } from 'signup-constants/signupData/HubInterestOption';
import { JobFieldOption } from 'signup-constants/signupData/JobFieldOption';
import { isOptionShufflingDisabled } from '../selenium/seleniumFeatures';
import { Data } from 'signup-constants/signupData/Data';
import { ProblemAwareness, ProductExperience } from './ConsiderationProfileOptions';
import { shuffleArray } from '../utils/data-structure-utils/shuffleArray';
import { eu1, na1 } from 'hubspot-url-utils/hublets-next';
const shuffleOptions = function shuffleOptions(options) {
  return isOptionShufflingDisabled() ? options : shuffleArray(options);
};
export const DataOptions = {
  [Data.CompanySize]: Object.values(CompanySizeOption),
  [Data.JobField]: [...shuffleOptions([JobFieldOption.Sales, JobFieldOption.Marketing, JobFieldOption.Support, JobFieldOption.Admin]), JobFieldOption.Other],
  [Data.CrmExperienceLevel]: [CrmExperienceOption.None, CrmExperienceOption.HasUsedCrm, CrmExperienceOption.HasUsedHubSpot],
  [Data.HubInterest]: shuffleOptions([HubInterestOption.Sales, HubInterestOption.CustomerService, HubInterestOption.Marketing, HubInterestOption.CMS]),
  [Data.HubletSelection]: [na1, eu1],
  [Data.CmsSignupIntent]: [CmsSignupIntentOption.Business, CmsSignupIntentOption.Personal],
  [Data.CmsBusinessMaturity]: [CmsBusinessMaturityOption.ExploringIdea, CmsBusinessMaturityOption.ReadyToLaunch, CmsBusinessMaturityOption.RecentlyLaunched, CmsBusinessMaturityOption.EstablishedBusiness],
  [Data.ProductExperience]: [ProductExperience.yes, ProductExperience.no],
  [Data.ProblemAwareness]: [ProblemAwareness.yes, ProblemAwareness.no]
};