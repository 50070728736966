import { useSelector } from 'react-redux';
import { getHttpResponse } from '../store/http-responses/selectors';
import { getIsNewUser } from '../store/app/selectors';
import { EndpointKey } from '../http/EndpointKey';
export const useHubletOverrides = () => {
  const isNewUser = useSelector(getIsNewUser);
  const endpointKey = isNewUser ? EndpointKey.HubletChoiceNewUser : EndpointKey.HubletChoiceExistingUser;
  const availableHubletsResponse = useSelector(state => getHttpResponse(state, endpointKey));
  if (!availableHubletsResponse) {
    return {
      hubletOverrides: null
    };
  }
  const availableHublets = availableHubletsResponse.availableHublets;
  if (availableHublets !== null && availableHublets !== void 0 && availableHublets.length && (availableHublets === null || availableHublets === void 0 ? void 0 : availableHublets.length) > 1) {
    return {
      hubletOverrides: availableHublets
    };
  }
  return {
    hubletOverrides: null
  };
};