import enviro from 'enviro';
import { AppKey } from '../../app/AppKey';
import { AppActionTypes } from './action-types';
export const initialAppState = {
  [AppKey.Hublet]: enviro.getHublet(),
  [AppKey.VerificationType]: null,
  [AppKey.VerificationExpired]: false,
  [AppKey.IsNewPortalCreated]: false,
  [AppKey.IsNewUser]: false,
  [AppKey.CompleteVerificationComplete]: false,
  [AppKey.HasRefreshedNewPortalAuth]: false,
  [AppKey.Country]: null,
  [AppKey.IsUsingOptionalDomain]: false,
  [AppKey.IsOAuthExistingUser]: false,
  [AppKey.IsExistingUserSession]: false,
  [AppKey.RedirectUrl]: '',
  [AppKey.FirstOAuthPartnerClick]: '',
  [AppKey.IsVerifiedByGoogleCredential]: false,
  [AppKey.WhichOAuthEndpointFailed]: null,
  [AppKey.IsNewUserCreated]: false,
  [AppKey.WhichOAuthSelected]: null,
  [AppKey.HasAcceptedCookies]: false,
  [AppKey.WhichOAuthLoading]: null,
  [AppKey.IsFreeEmailDomain]: false,
  [AppKey.RecommendedHublet]: null
};
const appReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case AppActionTypes.UpdateKeyValue:
      return Object.assign({}, state, {
        [action.key]: action.value
      });
    default:
      return state;
  }
};
export default appReducer;