import styled, { css, keyframes } from 'styled-components';
const appear = keyframes(["0%{transform:translateX(20px);opacity:0;}100%{transform:translateX(0);opacity:1;visibility:visible;}"]);
export const animationCss = css(["transition:all;animation:", " 0.4s cubic-bezier(0.42,0,0.58,1);animation-fill-mode:forwards;"], appear);
const disappear = keyframes(["0%{opacity:1;transform:translateX(0);}100%{opacity:0;transform:translateX(-50px);}"]);
export const AnimatedStep = styled.div.withConfig({
  displayName: "Animationsstyles__AnimatedStep",
  componentId: "p16zyh-0"
})([".fadeOut{animation:", " 0.3s ease 0s 1 normal;animation-fill-mode:forwards;}", " ", " ", ""], disappear, animationCss, props => props.animationDelay ? `
      animation-delay: 0.1s;
      visibility: hidden;
      ` : '', props => props.disableAnimation ? `animation: none` : '');