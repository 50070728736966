import { campaignAssistantRedirect, clipCreatorRedirect, walkthrough, landingPageCreatorRedirect, landingPageCreatorRedirectV2, invoiceGeneratorRedirect, blogIdeasGeneratorMicroappRedirect, marketingEmailCreatorRedirect } from '../intents/intentLinks';
import { getQueryParams } from '../routing/getQueryParams';
import { getHublet } from '../store/app/selectors';
import { getPortalId, getAuthState } from '../store/auth/selectors';
import { getInvoiceGeneratorState } from '../store/microapp/selectors';
import { GATES } from '../constants/gates';
export const getCampaignAssistantRedirect = state => {
  return campaignAssistantRedirect(getPortalId(state), getHublet(state), getQueryParams());
};
export const getBlogIdeasGeneratorRedirect = state => {
  return blogIdeasGeneratorMicroappRedirect(getPortalId(state), getHublet(state), getQueryParams());
};
export const getClipCreatorRedirect = state => {
  return clipCreatorRedirect(getPortalId(state), getHublet(state));
};
export const getGuideCreatorRedirect = state => {
  return walkthrough(getPortalId(state), getHublet(state), getQueryParams());
};
export const getLandingPageCreatorRedirect = state => {
  if (getAuthState(state).gates.includes(GATES.GPT_SIGNUP_REDIRECTION)) {
    return landingPageCreatorRedirectV2(getPortalId(state), getHublet(state), getQueryParams());
  }
  return landingPageCreatorRedirect(getPortalId(state), getHublet(state), getQueryParams());
};
export const getInvoiceGeneratorRedirect = state => {
  const invoiceId = getInvoiceGeneratorState(state).invoiceId;
  return invoiceGeneratorRedirect(getPortalId(state), getHublet(state), Object.assign({}, getQueryParams(), {
    invoiceId: invoiceId ? invoiceId.toString() : ''
  }));
};
export const getMarketingEmailCreatorRedirect = state => {
  return marketingEmailCreatorRedirect(getPortalId(state), getHublet(state), getQueryParams());
};