import { AppName } from 'signup-ui-lego-core/constants/AppName';
import { DefaultFlowOptions } from '../flow-initial/FlowOptions';
import { Microapp } from 'signup-constants/Microapp';
import { getMicroappKey } from '../routing/getMicroappKey';
import { isMicroappValid } from 'signup-ui-lego-core/utils/isMicroappValid';
import { SignupApiFlowPath } from '../http-clients/SignupApiFlowPath';
import { HubInterestOption } from 'signup-constants/signupData/HubInterestOption';
import { getIsNewUser } from '../store/app/selectors';
import { isEndpointStatusError, isEndpointStatusSuccess, hasEndpointBeenFetched } from '../store/http-responses/selectors';
import { EndpointKey } from '../http/EndpointKey';
import { getInvoiceGeneratorState } from '../store/microapp/selectors';
import { OAuthProviders } from '../oauth/OAuthProviders';
import { getAuthState } from '../store/auth/selectors';
export const defaultMicroappFlowOptions = Object.assign({}, DefaultFlowOptions, {
  appName: AppName.Microapp,
  showMobileAppPrompt: false,
  signupApiFlowPath: SignupApiFlowPath.Microapp,
  oAuthsEnabled: {
    [OAuthProviders.Google]: true,
    [OAuthProviders.Microsoft]: true,
    [OAuthProviders.Apple]: true
  }
});
const FlowOptionsByMicroapp = {
  [Microapp.CampaignAssistant]: Object.assign({}, defaultMicroappFlowOptions, {
    shouldHalveProgressBar: true
  }),
  [Microapp.GuideCreator]: Object.assign({}, defaultMicroappFlowOptions),
  [Microapp.ClipCreator]: Object.assign({}, defaultMicroappFlowOptions, {
    shouldHalveProgressBar: true
  }),
  [Microapp.EmailSignatureGenerator]: Object.assign({}, defaultMicroappFlowOptions),
  [Microapp.BrandKitGenerator]: defaultMicroappFlowOptions,
  [Microapp.BlogIdeasGenerator]: Object.assign({}, defaultMicroappFlowOptions, {
    canRedirect: state => {
      return getIsNewUser(state) ? isEndpointStatusSuccess(EndpointKey.CheckScopesForBlogIdeasGeneratorNewUser)(state) : true;
    }
  }),
  [Microapp.LandingPageCreator]: Object.assign({}, defaultMicroappFlowOptions, {
    canRedirect: state => {
      return getAuthState(state).gates.length === 0 ? hasEndpointBeenFetched(state, EndpointKey.GateLookup) : true;
    }
  }),
  [Microapp.WebsiteGrader]: Object.assign({}, defaultMicroappFlowOptions, {
    isOptionalDomainEnabled: true,
    hubInterest: HubInterestOption.CMS
  }),
  [Microapp.InvoiceGenerator]: Object.assign({}, defaultMicroappFlowOptions, {
    shouldRedirectExistingUserAutomatically: false,
    canRedirect: state => {
      const invoiceGeneratorState = getInvoiceGeneratorState(state);
      return isEndpointStatusSuccess(EndpointKey.InvoiceSignupBridge)(state) && invoiceGeneratorState.productBridgeComplete || isEndpointStatusError(EndpointKey.InvoiceSignupBridge)(state);
    }
  }),
  [Microapp.MarketingEmailCreator]: Object.assign({}, defaultMicroappFlowOptions)
};
export const getMicroappFlowOptions = () => {
  const microappKey = getMicroappKey();
  if (!microappKey || !isMicroappValid(microappKey)) {
    return defaultMicroappFlowOptions;
  }
  return FlowOptionsByMicroapp[microappKey];
};