import { initialAppState } from './app/reducer';
import { initialAuthState } from './auth/reducer';
import { initialErrorState } from './error/reducer';
import { initialExistingAccountsState } from './existing-accounts/reducer';
import { initialExperimentsState } from './experiments/reducer';
import { initialFlowState } from './flow/reducer';
import { initialGoogleRecaptchaState } from './google-recaptcha/reducer';
import { initialHttpResponsesState } from './http-responses/reducer';
import { initialIntegrationsState } from './integrations/reducer';
import { initialLoadingStatusState } from './loading-status/reducer';
import { initialMicroappState } from './microapp/reducer';
import { initialPredictionsState } from './predictions/reducer';
import { initialSignupDataState } from './signup-data/reducer';
import { initialTypeaheadState } from './typeahead/reducer';
export const getInitialState = () => {
  return {
    appState: initialAppState,
    auth: initialAuthState,
    error: initialErrorState,
    existingAccounts: initialExistingAccountsState,
    experiments: initialExperimentsState,
    flow: initialFlowState,
    httpResponses: initialHttpResponsesState,
    integrations: initialIntegrationsState,
    loadingStatus: initialLoadingStatusState,
    signupData: initialSignupDataState,
    googleRecaptcha: initialGoogleRecaptchaState,
    typeahead: initialTypeaheadState,
    microapp: initialMicroappState,
    predictions: initialPredictionsState
  };
};