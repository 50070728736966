import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { getGTMEventSignupData } from '../store/signup-data/selectors';
const eventName = 'hs-form-submit';
const LOG = Logger.getLogger('GTMEventTracker');
export const trackGTMEvent = state => {
  try {
    const data = getGTMEventSignupData(state);
    const event = new CustomEvent(eventName, {
      detail: data
    });
    window.dispatchEvent(event);
  } catch (error) {
    LOG.error('Error sending the GTM event', {
      extra: getSentryExtra(error)
    });
  }
};