import UILink from 'UIComponents/link/UILink';
import styled from 'styled-components';
import { devices } from '../../styles/viewports.styles';
export const StyledManageCookiesContainer = styled.div.withConfig({
  displayName: "ManageCookiesstyles__StyledManageCookiesContainer",
  componentId: "sc-1gf8bx2-0"
})(["font-size:17px !important;text-align:center;display:flex;justify-content:center;align-items:end;margin-top:40px;@media ", "{z-index:2147483647 !important;margin-top:0;display:block;text-align:end;position:fixed;bottom:0;padding-bottom:30px;right:100px;}"], devices.desktop);
export const StyledManageCookiesLink = styled(UILink).withConfig({
  displayName: "ManageCookiesstyles__StyledManageCookiesLink",
  componentId: "sc-1gf8bx2-1"
})(["color:#0b8484 !important;"]);