import { Data } from 'signup-constants/signupData/Data';
import { getSignupDataValue } from '../store/signup-data/selectors';
import { Step } from 'signup-constants/Step';
import { getRecommendedHublet } from '../store/app/selectors';
export const getPropertiesToTrack = state => {
  const recommendedHublet = getRecommendedHublet(state);
  const hublet = getSignupDataValue(state, Data.HubletSelection);
  const isUsingHubletRecommendation = hublet === recommendedHublet;
  return {
    [Step.HubletSelection]: {
      isUsingHubletRecommendation
    }
  };
};