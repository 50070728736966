import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { getFlowKey } from '../routing/getFlowKey';
import { getParameter } from '../store/experiments/selectors';
import { ExperimentKeys } from '../experiments/ExperimentKeys';
export const getNewUserHubletChoice = state => {
  const ACQ0162Parameter = getParameter(state, ExperimentKeys.ACQ0162);
  const ACQ0157Parameter = getParameter(state, ExperimentKeys.ACQ0157);
  const experimentKeys = [`${ExperimentKeys.ACQ0162}-group-${ACQ0162Parameter}`, `${ExperimentKeys.ACQ0157}-group-${ACQ0157Parameter}`];
  return noAuthApiClient.post('signup/v1/location/new-user-available-hublets', {
    data: {
      flowKey: getFlowKey(),
      experimentKeys
    }
  });
};