import { getFlowKey } from '../routing/getFlowKey';
import { getIntegrationKey } from '../routing/getIntegrationKey';
import { Step } from 'signup-constants/Step';
import { MessageKey } from './MessageKey';
import { Integration } from 'signup-constants/Integration';
import { getMicroappKey } from '../routing/getMicroappKey';
import { Microapp } from 'signup-constants/Microapp';
const SpecificMessaging = new Map([[Integration.Wordpress, () => new Map([[Step.WordpressAuthorization, MessageKey.WordpressConnect], [Step.WordpressConnectRedirect, MessageKey.WordpressConnect], [Step.CrmExperienceLevel, null]])], [Integration.ShopifyEmbedded, () => new Map([[Step.IntegratedAppAuthorization, MessageKey.ShopifyEmbeddedConnect], [Step.IntegratedAppConnectRedirect, MessageKey.ShopifyEmbeddedConnect], [Step.CrmExperienceLevel, null]])], [Microapp.CampaignAssistant, () => new Map([[Step.LandingPage, MessageKey.CampaignAssistant]])], [Microapp.BlogIdeasGenerator, () => new Map([[Step.LandingPage, MessageKey.BlogGenerator]])], [Microapp.LandingPageCreator, () => new Map([[Step.LandingPage, MessageKey.LandingPageCreator]])], [Microapp.InvoiceGenerator, () => new Map([[Step.LandingPage, MessageKey.InvoiceGenerator]])], [Microapp.MarketingEmailCreator, () => new Map([[Step.LandingPage, MessageKey.MarketingEmailCreator]])]]);
export const getSpecificMessagingFunction = () => {
  const flowKey = getFlowKey();
  const integrationKey = getIntegrationKey();
  const microappKey = getMicroappKey();
  return microappKey && SpecificMessaging.get(microappKey) || integrationKey && SpecificMessaging.get(integrationKey) || SpecificMessaging.get(flowKey);
};