import JobRoleService from 'signup-ui-job-role/services/JobRoleService';
import { Data } from 'signup-constants/signupData/Data';
import { getCompanySizeFormatted } from '../../signup-data/getCompanySizeFormatted';
import { getDataKeysByStep } from '../../step/getStepData';
import trimEmptyValuesFromObject from '../../utils/trimEmptyValuesFromObject';
import { Step } from 'signup-constants/Step';
import { getPostAccountCreationSteps, getPostExistingAccountSelectionSteps, getPreVerificationSteps } from '../flow/selectors';
import { DataStateKey } from './types';
import { JobRoleFromManagementLevel, ManagementLevelFormValue } from 'signup-constants/signupData/ManagementLevel';
import { getConsiderationProfile, getProblemAwarenessForApi, getProductExperienceForApi } from '../../signup-data/considerationProfileUtils';
export const getSignupData = state => state.signupData;
export const getSignupDataEntries = state => {
  return Object.entries(getSignupData(state));
};
export const getSignupDataEntry = (state, dataPoint) => {
  return getSignupData(state)[dataPoint];
};
export const getSignupDataValue = (state, dataKey) => {
  return getSignupDataEntry(state, dataKey)[DataStateKey.value];
};
export const getSignupDataPrefilled = (state, dataKey) => getSignupDataEntry(state, dataKey)[DataStateKey.prefilled];
export const getSignupDataPrefillType = (state, dataKey) => getSignupDataEntry(state, dataKey)[DataStateKey.prefillType];
export const getSignupDataValidation = (state, dataKey) => getSignupDataEntry(state, dataKey)[DataStateKey.validation];
export const getSignupDataErrorMessage = (state, dataKey) => getSignupDataEntry(state, dataKey)[DataStateKey.errorMessage];
export const getSignupDataSkipped = (state, dataKey) => getSignupDataEntry(state, dataKey)[DataStateKey.skipped];
export const getSignupDataEntriesForDataKeys = (state, dataPoints) => dataPoints.map(dataPoint => getSignupDataEntry(state, dataPoint));
export const getSignupDataValues = (state, dataPoints) => dataPoints.map(dataPoint => getSignupDataValue(state, dataPoint));
export const getSignupDataValidations = (state, dataPoints) => dataPoints.map(dataPoint => getSignupDataValidation(state, dataPoint));
export const getSignupDataErrorMessages = (state, dataPoints) => dataPoints.map(dataPoint => getSignupDataErrorMessage(state, dataPoint));
export const getFlatSignupDataValueMap = state => {
  return getSignupDataEntries(state).reduce((acc, [dataPoint, {
    value
  }]) => {
    acc[dataPoint] = value;
    return acc;
  }, {});
};
export const getIndustry = state => {
  return getSignupDataValue(state, Data.Industry);
};
export const getOptionalPhoneNumber = state => getSignupDataValue(state, Data.OptionalPhoneNumber) || getSignupDataValue(state, Data.PhoneNumber) || '';
export const signupDataAreFilled = (state, dataPoints) => {
  if (dataPoints.length === 0) {
    return true;
  }
  return getSignupDataEntriesForDataKeys(state, dataPoints).filter(data => !data[DataStateKey.skipped]).every(data => data && Boolean(data[DataStateKey.value]));
};
const signupDataAreCompleted = (state, dataPoints) => {
  if (dataPoints.length === 0) {
    return true;
  }
  return getSignupDataEntriesForDataKeys(state, dataPoints).filter(data => !data[DataStateKey.skipped]).every(data => {
    return data && Boolean(data[DataStateKey.value]) && data[DataStateKey.completed];
  });
};
export const isSignupDataCompleted = (state, datapoint) => {
  const data = getSignupDataEntry(state, datapoint);
  return data && Boolean(data[DataStateKey.value]) && data[DataStateKey.completed];
};
export const getFilledSignupData = state => {
  const dataKeys = Object.values(Data);
  return dataKeys.reduce((filledData, dataPoint) => {
    const value = getSignupDataValue(state, dataPoint);
    if (value) {
      filledData[dataPoint] = value;
    }
    return filledData;
  }, {});
};
export const getPreFilledSignupDataKeys = state => {
  const prefilledDataKeys = Object.values(Data).filter(key => getSignupDataPrefilled(state, key));
  return prefilledDataKeys;
};
export const getPreFilledSignupDataKeysByPrefillType = (state, prefillType) => {
  const prefilledDataKeys = Object.values(Data).filter(key => getSignupDataPrefillType(state, key) === prefillType);
  return prefilledDataKeys;
};
const getJobRoleData = state => {
  const managementLevel = getSignupDataValue(state, Data.ManagementLevel);
  const seniority = ManagementLevelFormValue[managementLevel];
  return seniority ? JobRoleFromManagementLevel[managementLevel] : JobRoleService.getCategory({
    id: getSignupDataValue(state, Data.JobRole)
  });
};
export const getPreAccountCreationSurveyData = state => {
  const {
    companySizeNumber,
    companySizeLabel
  } = getCompanySizeFormatted(getSignupDataValue(state, Data.CompanySize));
  const managementLevel = getSignupDataValue(state, Data.ManagementLevel);
  const seniority = ManagementLevelFormValue[managementLevel];
  const jobRole = getJobRoleData(state);
  const jobRoleDetailed = getSignupDataValue(state, Data.JobRole);
  const productExperience = getSignupDataValue(state, Data.ProductExperience);
  const problemAwareness = getSignupDataValue(state, Data.ProblemAwareness);
  const considerationProfile = getConsiderationProfile(state);
  return Object.assign({}, companySizeLabel && {
    companySize: companySizeLabel
  }, {
    jobRole,
    jobRoleDetailed
  }, companySizeNumber && {
    companySizeNumber
  }, {
    industry: getIndustry(state)
  }, seniority && {
    jobTitle: seniority
  }, problemAwareness && {
    problemAwareness
  }, productExperience && {
    productExperience
  }, considerationProfile && {
    profileId: considerationProfile
  });
};
export const getAccountCreatedFormSurveyData = state => {
  const {
    companySizeNumber
  } = getCompanySizeFormatted(getSignupDataValue(state, Data.CompanySize));
  const optionalPhoneNumber = getOptionalPhoneNumber(state);
  const crmExperience = getSignupDataValue(state, Data.CrmExperienceLevel);
  const hubInterest = getSignupDataValue(state, Data.HubInterest);
  const managementLevel = getSignupDataValue(state, Data.ManagementLevel);
  const seniority = ManagementLevelFormValue[managementLevel];
  const jobRole = getJobRoleData(state);
  const jobRoleDetailed = getSignupDataValue(state, Data.JobRole);
  const productExperience = getProductExperienceForApi(state);
  const problemAwareness = getProblemAwarenessForApi(state);
  const considerationProfile = getConsiderationProfile(state);
  return trimEmptyValuesFromObject(Object.assign({
    companySize: companySizeNumber,
    jobRoleDetailed,
    industry: getIndustry(state)
  }, jobRole && {
    salesSignupJobRole: jobRole
  }, {
    jobField: getSignupDataValue(state, Data.JobField)
  }, optionalPhoneNumber && {
    phone: optionalPhoneNumber
  }, crmExperience && {
    crmExperience
  }, hubInterest && {
    hubInterest
  }, seniority && {
    seniority
  }, problemAwareness !== null && {
    problemAwareness
  }, productExperience !== null && {
    productExperience
  }, considerationProfile && {
    considerationProfile
  }));
};
export const getSurveyData = state => {
  const [crmExperience, jobField, hubInterest, cmsSignupIntent, cmsBusinessMaturity] = getSignupDataValues(state, [Data.CrmExperienceLevel, Data.JobField, Data.HubInterest, Data.CmsSignupIntent, Data.CmsBusinessMaturity]);
  return Object.assign({
    crmExperience,
    jobField
  }, hubInterest && {
    hubInterest
  }, cmsSignupIntent && {
    purpose: cmsSignupIntent
  }, cmsBusinessMaturity && {
    stage: cmsBusinessMaturity
  }, getPreAccountCreationSurveyData(state));
};
export const getSurveyDataForRedirect = state => {
  const surveyData = getSurveyData(state);
  const companySize = {
    companySizeLabel: surveyData.companySize,
    companySizeNumber: surveyData.companySizeNumber
  };
  const cmsSignupIntent = surveyData.purpose;

  // We do not send the job field to the redirect library if
  // we are on a flow where we ask the hubInterest.
  if (surveyData.hubInterest) {
    delete surveyData.jobField;
  }
  delete surveyData.phoneNumberSubmitted;
  delete surveyData.companySizeNumber;
  delete surveyData.purpose;
  delete surveyData.stage;
  return Object.assign({}, surveyData, {
    companySize,
    cmsSignupIntent
  });
};
export const hasAnsweredAllPostAccountCreatedQuestions = state => {
  let postAccountCreation = getPostAccountCreationSteps(state);
  if (postAccountCreation.includes(Step.HubInterest) && !postAccountCreation.includes(Step.JobField)) {
    postAccountCreation = [...postAccountCreation, Step.JobField];
  }
  const stepData = getDataKeysByStep(postAccountCreation);
  const allCompleted = signupDataAreCompleted(state, stepData);
  return allCompleted;
};
export const hasAnsweredAllPostExistingAccountSelectedQuestions = state => {
  const postExistingAccountSelection = getPostExistingAccountSelectionSteps(state);
  if (postExistingAccountSelection.length === 0) {
    return false;
  }
  const stepData = getDataKeysByStep(postExistingAccountSelection);
  const allCompleted = signupDataAreCompleted(state, stepData);
  return allCompleted;
};
export const isVerificationStep = stepKey => {
  return stepKey === Step.Verification || stepKey === Step.CodeVerification;
};
export const getPreVerificationDataKeys = state => {
  const preVerification = getPreVerificationSteps(state);
  const steps = preVerification.filter(step => !isVerificationStep(step));
  return getDataKeysByStep(steps);
};
export const getCountry = state => getSignupDataValue(state, Data.Country) || '';
export const isAsyncValidationResultUpToDate = (state, dataKey, validatedValue) => {
  const signupDataItem = getSignupDataEntry(state, dataKey);
  return signupDataItem && signupDataItem.value === validatedValue;
};
export const getGTMEventSignupData = state => {
  const firstName = getSignupDataValue(state, Data.FirstName);
  const lastName = getSignupDataValue(state, Data.LastName);
  const email = getSignupDataValue(state, Data.Email);
  return Object.assign({}, getPreAccountCreationSurveyData(state), firstName && {
    firstName
  }, lastName && {
    lastName
  }, email && {
    email
  });
};