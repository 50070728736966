import styled from 'styled-components';
import UIProgress from 'UIComponents/progress/UIProgress';
export const StyledProgressIndicator = styled.div.withConfig({
  displayName: "ProgressIndicatorstyles__StyledProgressIndicator",
  componentId: "ggi5gi-0"
})(["transition:all 0.6s ease-in-out;position:fixed;right:0;left:0;top:0;z-index:20;"]);
export const ProgressIndicatorWrapper = styled.div.withConfig({
  displayName: "ProgressIndicatorstyles__ProgressIndicatorWrapper",
  componentId: "ggi5gi-1"
})(["height:8px;border-radius:0 3px 3px 0;overflow:hidden;> *{margin:0;border-radius:0;}"]);
export const ProgressIndicatorProgress = styled(UIProgress).withConfig({
  displayName: "ProgressIndicatorstyles__ProgressIndicatorProgress",
  componentId: "ggi5gi-2"
})(["height:8px;border-radius:0;[role='progressbar']{border-radius:0;background:#ff5c35;}"]);