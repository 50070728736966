try {
  hubspot.require(['enviro'], function(env) {
    var key = 'google_analytics';
    let gtmId = ''

    if (env.deployed(key) && env.getInternal(key) === 'prod') {
      gtmId = 'GTM-TDNGMT'
    }

    const script = document.createElement('script');
    const loadQaScript = window.location.search.includes('gdprqa=true');
    const scriptSrc = loadQaScript
      ? 'https://www.hubspot.com/wt-assets/static-files/compliance/next/index.js'
      : 'https://www.hubspot.com/wt-assets/static-files/compliance/index.js';
    script.type = 'text/javascript';
    script.src = scriptSrc;
    script.setAttribute('data-gtm-id', gtmId);
    script.setAttribute('data-external-hs-domain', 'true')

    const head = document.querySelector('head');

    if (head) {
      head.appendChild(script);
    }

  })
} catch (exception) {
  console.warn('Failed to setup the Compliance Script', exception);
}





