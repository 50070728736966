import styled from 'styled-components';
import { devices } from '../../styles/viewports.styles';
import { LayoutType } from '../../types';
export const MessagingContentHolder = styled.div.withConfig({
  displayName: "MessageContainerstyles__MessagingContentHolder",
  componentId: "natnnx-0"
})(["width:100%;text-align:center;@media ", "{width:450px;overflow:visible;", "}"], devices.desktop, props => props.layoutType === LayoutType.LeftAlignedTwoColumn && `width: 100%`);
export const FlowStepMessage = styled.div.withConfig({
  displayName: "MessageContainerstyles__FlowStepMessage",
  componentId: "natnnx-1"
})(["display:grid;align-content:center;", " ", " @media ", "{", "}"], props => props.layoutType === LayoutType.LeftAlignedTwoColumn && `
    align-content: start;
    grid-column: span 2;
    `, props => [LayoutType.CentredStep, LayoutType.TwoColumn].includes(props.layoutType) && `  margin: 2rem auto;`, devices.desktop, props => props.layoutType === LayoutType.LeftAlignedTwoColumn ? 'grid-column: span 1' : 'margin: 0 auto');