import { SALES_SIGNUP } from 'growth-onboarding-signup-redirect/constants/signupConstants';
import { Flow } from 'signup-constants/Flow';
import { Integration } from 'signup-constants/Integration';
import { Microapp } from 'signup-constants/Microapp';
import { getFlowKey } from '../routing/getFlowKey';
import { getIntegrationKey } from '../routing/getIntegrationKey';
import { getMicroappKey } from '../routing/getMicroappKey';
import { getStandaloneCmsPartnerRedirect } from '../standalone-cms-partner/getStandaloneCmsPartnerRedirect';
import { getTrialRedirect } from '../trial/getTrialRedirect';
import { isOauthFlow } from '../utils/oauthUtils';
import { getAcademyRedirect } from './academyRedirect';
import { getCMSDeveloperRedirect } from './cmsDeveloperRedirect';
import { getDeveloperRedirect } from './developerRedirect';
import { getDirectoryListingRedirect } from './directoryListingRedirect';
import { getAssetProviderRedirect } from './getAssetProviderRedirect';
import { getCommerceRedirect } from './getCommerceRedirect';
import { getConnectFlowRedirect } from './getConnectFlowRedirect';
import { getFacebookCrmRedirect } from './getFacebookCrmRedirect';
import { getBlogIdeasGeneratorRedirect, getCampaignAssistantRedirect, getClipCreatorRedirect, getGuideCreatorRedirect, getInvoiceGeneratorRedirect, getLandingPageCreatorRedirect, getMarketingEmailCreatorRedirect } from './getMicroappRedirect';
import { getOauthRedirect } from './getOauthRedirect';
import { getSolutionsProviderRedirect } from './getSolutionsProviderRedirect';
import { getIntegratedAppRedirect, getWordPressRedirect } from './getIntegratedAppRedirects';
import { getHubSpotForStartupsRedirect, getHubSpotPartnersRedirect } from './hubspotForXRedirect';
import { getEngageAIFlowRedirect } from './getEngageAIFlowRedirect';
const SpecificRedirect = new Map([[Flow.Academy, getAcademyRedirect], [Flow.Developers, getDeveloperRedirect], [Flow.CmsDevelopers, getCMSDeveloperRedirect], [Flow.HubspotForStartups, getHubSpotForStartupsRedirect], [Flow.SolutionsProvider, getSolutionsProviderRedirect], [Flow.DirectoryListing, getDirectoryListingRedirect], [Flow.HubSpotPartners, getHubSpotPartnersRedirect], [Flow.FbCrm, getFacebookCrmRedirect], [Integration.Wordpress, getWordPressRedirect], [Integration.ShopifyEmbedded, getIntegratedAppRedirect], [Flow.Trial, getTrialRedirect], [Flow.StandaloneCmsPartner, getStandaloneCmsPartnerRedirect], [Flow.Connect, getConnectFlowRedirect], [Flow.AssetProvider, getAssetProviderRedirect], [Microapp.CampaignAssistant, getCampaignAssistantRedirect], [Microapp.BlogIdeasGenerator, getBlogIdeasGeneratorRedirect], [Microapp.ClipCreator, getClipCreatorRedirect], [Microapp.GuideCreator, getGuideCreatorRedirect], [Microapp.LandingPageCreator, getLandingPageCreatorRedirect], [Microapp.InvoiceGenerator, getInvoiceGeneratorRedirect], [Microapp.MarketingEmailCreator, getMarketingEmailCreatorRedirect], [Flow.Commerce, getCommerceRedirect], [Flow.EngageAI, getEngageAIFlowRedirect]]);
const SpecificEnrollment = new Map([[Flow.HubspotForStartups, SALES_SIGNUP]]);
function getFlowIntegrationOrMicroappKey() {
  const flowKey = getFlowKey();
  const integrationKey = getIntegrationKey();
  const microappKey = getMicroappKey();
  return microappKey || integrationKey || flowKey;
}
export const getSpecificRedirect = () => {
  const specificRedirect = SpecificRedirect.get(getFlowIntegrationOrMicroappKey());
  return isOauthFlow() ? getOauthRedirect : specificRedirect;
};
export const getSpecificEnrollment = () => {
  return SpecificEnrollment.get(getFlowIntegrationOrMicroappKey());
};