import { AppKey } from '../../app/AppKey';
import { Metrics } from '../../metrics/Metrics';
import TrackerContainer from '../../tracking/TrackerContainer';
import { AppActionTypes } from './action-types';
import { MetricsCounter } from '../../metrics/MetricsCounter';
export const updateKeyValue = (key, value) => dispatch => {
  return dispatch({
    type: AppActionTypes.UpdateKeyValue,
    key,
    value
  });
};
export const startApp = () => dispatch => {
  Metrics.counter(MetricsCounter.SignupInitiated).increment();
  return dispatch({
    type: AppActionTypes.AppStarted
  });
};
export const setNewPortalCreated = () => {
  return updateKeyValue(AppKey.IsNewPortalCreated, true);
};
export const setHublet = hublet => {
  return updateKeyValue(AppKey.Hublet, hublet);
};
export const setIsNewUser = isNewUser => {
  return updateKeyValue(AppKey.IsNewUser, isNewUser);
};
export const setIsNewUserCreated = isNewUser => {
  return updateKeyValue(AppKey.IsNewUserCreated, isNewUser);
};
export const setIsOAuthExistingUser = isOAuthExistingUser => {
  return updateKeyValue(AppKey.IsOAuthExistingUser, isOAuthExistingUser);
};
export const completeVerificationComplete = () => {
  return updateKeyValue(AppKey.CompleteVerificationComplete, true);
};
export const updateVerificationExpired = value => {
  return updateKeyValue(AppKey.VerificationExpired, value);
};
export const setVerificationType = verificationType => dispatch => {
  if (verificationType) {
    TrackerContainer.setProperties({
      verificationType
    });
  }
  return dispatch(updateKeyValue(AppKey.VerificationType, verificationType));
};
export const setHasRefreshedNewPortalAuth = hasRefreshedAuth => {
  return updateKeyValue(AppKey.HasRefreshedNewPortalAuth, hasRefreshedAuth);
};
export const setIsUsingOptionalDomain = value => {
  return updateKeyValue(AppKey.IsUsingOptionalDomain, value);
};
export const setIsExistingUserSession = value => {
  return updateKeyValue(AppKey.IsExistingUserSession, value);
};
export const setRedirectUrl = value => {
  return updateKeyValue(AppKey.RedirectUrl, value);
};
export const setWhichOAuthLoading = value => {
  return updateKeyValue(AppKey.WhichOAuthLoading, value);
};
export const setWhichOAuthSelected = provider => {
  return updateKeyValue(AppKey.WhichOAuthSelected, provider);
};
export const embeddedPrefillData = data => dispatch => {
  return dispatch({
    type: AppActionTypes.EmbeddedPrefill,
    data
  });
};
export const setCookiesAccepted = value => {
  return updateKeyValue(AppKey.HasAcceptedCookies, value);
};
export const updateCookiePreferences = ({
  newValue
}) => dispatch => {
  dispatch({
    type: AppActionTypes.UpdateCookiePreferences,
    newValue
  });
};
export const setIsFreeEmailDomain = () => {
  return updateKeyValue(AppKey.IsFreeEmailDomain, true);
};
export const setRecommendedHublet = recommendedHublet => {
  return updateKeyValue(AppKey.RecommendedHublet, recommendedHublet);
};