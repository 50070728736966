import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import noPortalApiClient from 'hub-http/clients/noPortalApiClient';
import { canUseExistingUserSignupEndpoint } from '../app/verificationTypeSelectors';
import { getCompleteVerificationBody } from '../http-complete-verification/getCompleteVerificationBody';
import { getInitiateVerificationBody } from '../http-initiate-verification/getInitiateVerificationBody';
import { getSignupBody } from '../http-signup/getSignupBody';
import { Tracker } from '../store/tracking/action-creators';
import TrackerContainer from '../tracking/TrackerContainer';
import { getCompleteVerificationPath, getInitiateVerificationPath, getSignupPath } from './signupClientPaths';
import { shouldSimulateRecaptchaFailure } from '../utils/shouldSimulateRecaptchaFailure';
const initiateVerification = (state, dispatch) => {
  dispatch(Tracker.signupInteraction('send-verification-email-request'));
  return noAuthApiClient.post(getInitiateVerificationPath(), {
    data: getInitiateVerificationBody(state)
  });
};
const completeVerification = state => {
  // setup tracking for email by link flow
  if (!TrackerContainer.isTrackerEnabled()) {
    TrackerContainer.setupTracking();
  }
  return noAuthApiClient.post(getCompleteVerificationPath(state), {
    data: getCompleteVerificationBody(state)
  });
};
const signup = state => {
  let httpClient = noAuthApiClient;
  const simulateRecaptchaFailure = shouldSimulateRecaptchaFailure(state);
  if (canUseExistingUserSignupEndpoint(state)) {
    httpClient = noPortalApiClient;
  }
  if (simulateRecaptchaFailure) {
    return httpClient.post(getSignupPath(state), {
      data: getSignupBody(state),
      timeout: 6000,
      query: {
        simulateRecaptchaFailure: true
      }
    });
  }
  return httpClient.post(getSignupPath(state), {
    data: getSignupBody(state),
    timeout: 6000
  });
};
const SignupClient = {
  initiateVerification,
  completeVerification,
  signup
};
export default SignupClient;