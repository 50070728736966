import styled from 'styled-components';
import UIIllustration from 'UIComponents/image/UIIllustration';
import { devices } from '../../styles/viewports.styles';
import { LayoutType } from '../../types';
export const ViewContainerWrapper = styled.div.withConfig({
  displayName: "LayoutTemplatestyles__ViewContainerWrapper",
  componentId: "sc-1xqgccn-0"
})(["display:grid;grid-template-columns:1.5rem 3.125rem auto 3.125rem 1.5rem;grid-template-rows:minmax(2.5rem,max-content) 0 auto minmax( 2.5rem,max-content );grid-template-areas:'left-gutter header header header right-gutter' 'left-gutter subheader subheader subheader right-gutter' 'left-gutter main main main right-gutter' 'left-gutter submain submain submain right-gutter';min-height:100vh;padding-top:0.5rem;max-width:32.5rem;margin:0 auto;overflow:hidden;@media ", "{max-width:37.5rem;}@media ", "{grid-template-columns:2.5rem 3.125rem auto 3.125rem 2.5rem;grid-template-rows:minmax(2.5rem,max-content) 0 auto minmax( 2.5rem,max-content );max-width:100vw;margin:0 auto;}", ";"], devices.tablet, devices.desktop, props => props.layoutType === LayoutType.FramedCentredModalStep && `
    @media ${devices.desktop} {
    background: rgba(0, 0, 0, 0.20);
  }`);
export const BackButton = styled.div.withConfig({
  displayName: "LayoutTemplatestyles__BackButton",
  componentId: "sc-1xqgccn-1"
})(["display:none;grid-template-rows:auto auto;grid-area:subheader;z-index:1;@media ", "{display:initial;}"], devices.desktop);

// Storybook styles
export const ContentTestComponent = styled.div.withConfig({
  displayName: "LayoutTemplatestyles__ContentTestComponent",
  componentId: "sc-1xqgccn-2"
})(["height:500px;", ";"], props => props.layoutType !== LayoutType.FramedCentredStep && `border: 1px solid orange;`);
export const MessagingTestIllustration = styled(UIIllustration).withConfig({
  displayName: "LayoutTemplatestyles__MessagingTestIllustration",
  componentId: "sc-1xqgccn-3"
})(["@media ", "{width:60%;}"], devices.desktop);