import { getSentryExtra } from '../error/getSentryExtra';
import Logger from '../lib/Logger';
import { httpError, httpFetching, httpSuccess } from '../store/http-responses/action-creators';
import { HttpResponseActionTypes } from '../store/http-responses/action-types';
import { clearLoadingStatus, setLoadingStatus } from '../store/loading-status/action-creators';
import { hasLoadingClearingCondition } from '../store/loading-status/selectors';
import { getEndpoint, getEndpointConfig, setEndpointFetched } from './EndpointConfig';
import { logEndpointTriggered } from './logEndpointTriggered';
import { shouldRequestEndpoint } from './shouldRequestEndpoint';
export const LOG = Logger.getLogger('HttpRequester');
const fetch = (dispatch, getState, action) => endpointKey => {
  const endpoint = getEndpoint(endpointKey);
  if (!endpoint) {
    return Promise.reject(new Error('Endpoint Config does not exist'));
  }
  if (endpoint.loadingKey && action.type !== HttpResponseActionTypes.RetryEndpoint) {
    dispatch(setLoadingStatus(endpoint.loadingKey));
  }
  const before = Promise.resolve(endpoint.onBefore && endpoint.onBefore(dispatch, action, getState));
  return before.then(() => {
    dispatch(httpFetching(endpointKey));
    return endpoint.method(getState(), dispatch, action).then(response => {
      if (endpoint.handleResponse) {
        dispatch(endpoint.handleResponse(response));
      } else {
        dispatch(httpSuccess(endpointKey, response));
      }
    }).catch(error => {
      if (endpoint.handleResponse) {
        return dispatch(endpoint.handleResponse(error));
      }
      return dispatch(httpError(endpointKey, {
        message: error.message,
        status: error.status,
        data: error.data
      }));
    }).finally(() => {
      if (endpoint.loadingKey && !hasLoadingClearingCondition(endpoint.loadingKey)) {
        dispatch(clearLoadingStatus(endpoint.loadingKey));
      }
    });
  }).catch(error => LOG.error(`Fetch error: ${endpointKey}`, {
    extra: getSentryExtra(error)
  }));
};
const fetchAll = (action, dispatch, getState) => {
  const endpointKeys = Object.keys(getEndpointConfig());
  Promise.all(endpointKeys.filter(endpointKey => {
    const {
      triggered,
      triggerReason
    } = shouldRequestEndpoint(getState(), action, endpointKey);
    if (triggered) {
      logEndpointTriggered({
        endpointKey,
        triggerAction: action,
        triggerReason,
        state: getState()
      });
      setEndpointFetched(endpointKey);
    }
    return triggered;
  }).map(endpointKey => fetch(dispatch, getState, action)(endpointKey))).catch(error => LOG.error('Error with Http Requester fetch all', {
    extra: getSentryExtra(error)
  }));
};
const handleSuccess = (action, dispatch, getState) => {
  const endpointKey = action.endpointKey;
  const endpoint = getEndpoint(endpointKey);
  if (!endpoint) {
    return null;
  }
  const successHandler = endpoint.handleSuccess;
  return successHandler && successHandler(action.response || {}, dispatch, getState);
};
const handleError = (action, dispatch, getState) => {
  const endpointKey = action.endpointKey;
  const endpoint = getEndpoint(endpointKey);
  if (!endpoint) {
    return null;
  }
  const errorHandler = endpoint.handleError;
  return errorHandler && errorHandler(action.error || {}, dispatch, getState);
};
const HttpRequester = {
  fetchAll: (action, dispatch, getState) => fetchAll(action, dispatch, getState),
  handleSuccess: (action, dispatch, getState) => handleSuccess(action, dispatch, getState),
  handleError: (action, dispatch, getState) => handleError(action, dispatch, getState)
};
export default HttpRequester;