import styled from 'styled-components';
import { LayoutType } from '../../types';
import { devices, height } from '../../styles/viewports.styles';
import { KOALA, OLAF } from 'HubStyleTokens/colors';
import { isLeftAlignedLayout } from '../../utils/isLeftAlignedLayout';
export const FlowStepComponent = styled.div.withConfig({
  displayName: "ViewContainerstyles__FlowStepComponent",
  componentId: "sc-3wmb1o-0"
})(["", " ", " @media ", "{justify-content:center;align-content:center;", "}", ""], props => props.layoutType !== LayoutType.LeftAligned && `
    display: grid;
    align-content: center;
    flex: 1;
`, props => isLeftAlignedLayout(props.layoutType) && `
    align-content: start;
  `, devices.desktop, props => isLeftAlignedLayout(props.layoutType) && `
      justify-content: left;
      align-content: start;
    `, props => props.layoutType === LayoutType.CentredStep && props.alignCentredStepAtTheTop && `
    align-content: start;

    ${props.shouldAddTopPadding && `
      justify-content: center;
      @media ${height.small} {
      padding-top: 40px;
      align-content: start;
    }

    @media ${height.medium} {
      padding-top: 100px;
      align-content: start;
    }

    @media ${height.large} {
      padding-top: 10vh;
      align-content: start;
    }`}
    `);
export const ViewComponent = styled.div.withConfig({
  displayName: "ViewContainerstyles__ViewComponent",
  componentId: "sc-3wmb1o-1"
})(["display:grid;grid-template-rows:minmax(65vh,max-content) auto;grid-area:main;", " ", " @media ", "{width:100%;justify-self:center;grid-template-columns:50% 50%;grid-template-rows:auto;", " ", " ", "}@media ", "{", "}"], props => [LayoutType.CentredStep, LayoutType.FramedCentredStep, LayoutType.FramedCentredModalStep].includes(props.layoutType) && `grid-template-rows: 100%;`, props => (isLeftAlignedLayout(props.layoutType) || props.hasTitleContainer) && `
      grid-template-rows: fit-content(10%) auto;
    `, devices.desktop, props => props.hasTitleContainer && `
      grid-template-rows: fit-content(10%) auto;
    `, props => isLeftAlignedLayout(props.layoutType) && `
      grid-template-rows: fit-content(10%) auto;
      max-width: 1200px;
      `, props => [LayoutType.CentredStep, LayoutType.FramedCentredStep, LayoutType.FramedCentredModalStep, LayoutType.LeftAligned].includes(props.layoutType) && `grid-template-columns: 100%;`, devices.large, props => !isLeftAlignedLayout(props.layoutType) && `max-width: calc(100% - 1 * (100% - 1201px));`);
export const ContentHolder = styled.div.withConfig({
  displayName: "ViewContainerstyles__ContentHolder",
  componentId: "sc-3wmb1o-2"
})(["width:100%;@media ", ",@media ", ",{", " ", "}@media ", "{width:450px;overflow:visible;", ";", " ", "}@media ", "{", "}"], devices.tablet, devices.desktop, props => [LayoutType.FramedCentredStep, LayoutType.FramedCentredModalStep].includes(props.layoutType) && `
      min-height: 700px;
      height: 100%;
      border-radius: 8px;
      border: 1px solid ${KOALA};
      box-shadow: 0px 4px 20px rgba(81, 111, 144, 0.2);
      padding-top: 40px;
      padding-bottom: 30px;
      padding-left: 48px;
      padding-right: 48px;
      background-color: ${OLAF};
      `, props => props.layoutType === LayoutType.FramedCentredModalStep && ` border: none;
        box-shadow: none;
        `, devices.desktop, props => [LayoutType.FramedCentredStep, LayoutType.FramedCentredModalStep].includes(props.layoutType) && ` width: 528px;`, props => props.layoutType === LayoutType.CentredStep && ` width: 50rem;`, props => isLeftAlignedLayout(props.layoutType) && ` width: 100%;`, devices.large, props => props.layoutType === LayoutType.CentredStep && props.alignCentredStepAtTheTop && ` width: 70rem;`);
export const FlowStep = styled.div.withConfig({
  displayName: "ViewContainerstyles__FlowStep",
  componentId: "sc-3wmb1o-3"
})(["display:flex;flex-direction:column;", ""], props => props.layoutType === LayoutType.LeftAlignedTwoColumn && `
    display:grid;
    grid-column: span 2;

    @media ${devices.desktop} {
      grid-column: span 1;
    }
  `);
export const TitleContainerWrapper = styled.div.withConfig({
  displayName: "ViewContainerstyles__TitleContainerWrapper",
  componentId: "sc-3wmb1o-4"
})(["", ""], props => props.shouldAddTopMargin && `
    margin-top: 73px;
    grid-column: span 2;
    `);