import { getIsNewPortalCreated } from '../store/app/selectors';
import { isPortalIdDefined } from '../store/auth/selectors';
import { Tracker } from '../store/tracking/action-creators';
import { trackGTMEvent } from '../tracking/trackGTMEvent';
import { trackGoogleAnalyticsAndConvertGoal } from '../tracking/trackGoogleAnalyticsAndConvertGoal';
import { EndpointKey } from './EndpointKey';
export const EndpointsSignupCompleted = {
  [EndpointKey.TrackSignupCompleted]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: state => getIsNewPortalCreated(state) && isPortalIdDefined(state),
    method: (state, dispatch) => new Promise(resolve => {
      dispatch(Tracker.signupCompleted());
      trackGoogleAnalyticsAndConvertGoal();
      trackGTMEvent(state);
      resolve(true);
    })
  }
};