import { Data } from 'signup-constants/signupData/Data';
import { EndpointKey } from './EndpointKey';
import { Tracker } from '../store/tracking/action-creators';
import { getNewUserHubletChoice } from '../hublets/getNewUserHubletChoice';
import { isExistingUser } from '../app/verificationTypeSelectors';
import { getExistingUserHubletChoice } from '../hublets/getExistingUserHubletChoice';
import { getIsNewUser } from '../store/app/selectors';
import { setSignupDataValue } from '../store/signup-data/action-creators';
import { setRecommendedHublet } from '../store/app/action-creators';
import Logger from '../lib/Logger';
import { getSentryExtra } from '../error/getSentryExtra';
const LOG = Logger.getLogger('handleHubletChoiceError');
export const EndpointsAvailableHublets = {
  [EndpointKey.HubletChoiceNewUser]: {
    stateCheck: state => {
      return getIsNewUser(state);
    },
    once: true,
    method: getNewUserHubletChoice,
    handleSuccess: (response, dispatch) => {
      dispatch(Tracker.signupInteraction('set-hublet-in-state', {
        selectedOption: response.recommendedHublet,
        fields: response.availableHublets
      }));
      dispatch(setRecommendedHublet(response.recommendedHublet));
      dispatch(setSignupDataValue(Data.HubletSelection, response.recommendedHublet));
    },
    handleError: error => {
      LOG.error('Failed to get new user hublet choice', {
        extra: getSentryExtra(error)
      });
    }
  },
  [EndpointKey.HubletChoiceExistingUser]: {
    stateCheck: state => {
      return isExistingUser(state);
    },
    once: true,
    method: getExistingUserHubletChoice,
    handleSuccess: (response, dispatch) => {
      dispatch(Tracker.signupInteraction('set-hublet-in-state', {
        selectedOption: response.recommendedHublet,
        fields: response.availableHublets
      }));
      dispatch(setRecommendedHublet(response.recommendedHublet));
      dispatch(setSignupDataValue(Data.HubletSelection, response.recommendedHublet));
    },
    handleError: error => {
      LOG.error('Failed to get existing user hublet choice', {
        extra: getSentryExtra(error)
      });
    }
  }
};