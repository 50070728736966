import { Flow } from 'signup-constants/Flow';
import { getIntegrationFlowSteps } from '../integrations-flow/getIntegrationFlowSteps';
import { Step } from 'signup-constants/Step';
import { getMicroappFlowSteps } from '../microapp-flow/getMicroappFlowSteps';
const SoftwareFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
  postAccountCreation: [Step.CrmExperienceLevel],
  postExistingAccountSelection: []
};
const SoftwareFlowStepsWithoutHublet = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.ProductExperience, Step.ProblemAwareness, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
export const GenericFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
  postAccountCreation: [Step.CrmExperienceLevel, Step.JobField],
  postExistingAccountSelection: []
};
export const PurchaseFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
const AcademyFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.JobField, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
const DeveloperFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.JobRole, Step.CompanyName],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
const HubSpotForStartupsFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.CompanyName, Step.CompanyDomain, Step.CompanySize, Step.OptionalPhoneNumber],
  postAccountCreation: [Step.CrmExperienceLevel],
  postExistingAccountSelection: [Step.CompanySize, Step.OptionalPhoneNumber]
};
const NoSurveyFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.CompanyName, Step.CompanyDomain],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
const DirectoryListingFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.CompanyName, Step.CompanyDomain],
  postAccountCreation: [Step.CompanySize, Step.PhoneNumber],
  postExistingAccountSelection: [Step.CompanySize, Step.PhoneNumber]
};
const PartnerFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.ManagementLevel, Step.CompanyName, Step.CompanyDomain, Step.CompanySize, Step.OptionalPhoneNumber],
  postAccountCreation: [],
  postExistingAccountSelection: [Step.ManagementLevel, Step.CompanySize, Step.OptionalPhoneNumber]
};
const StandaloneCmsPartnerFlowSteps = {
  preVerification: [Step.UnauthedPartnerUserError],
  postVerification: [],
  postAccountCreation: [],
  postExistingAccountSelection: [Step.CmsPartnerCompanyDomainAndName]
};
const UserFlowsSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
const CmsFreeFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain, Step.CmsSignupIntent, Step.CmsBusinessMaturity],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
const CommerceFlowSteps = {
  preVerification: [Step.LandingPage, Step.CodeVerification],
  postVerification: [Step.Password, Step.Name, Step.ProductExperience, Step.ProblemAwareness, Step.Industry, Step.ManagementLevel, Step.CompanyName, Step.CompanySize, Step.CompanyDomain],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
const EngageAIFlowsSteps = {
  preVerification: [Step.LandingPage],
  postVerification: [],
  postAccountCreation: [],
  postExistingAccountSelection: []
};
export const FlowStepsMapping = {
  [Flow.Crm]: () => SoftwareFlowStepsWithoutHublet,
  [Flow.Marketing]: () => SoftwareFlowStepsWithoutHublet,
  [Flow.Sales]: () => SoftwareFlowStepsWithoutHublet,
  [Flow.Service]: () => SoftwareFlowStepsWithoutHublet,
  [Flow.Trial]: () => SoftwareFlowSteps,
  [Flow.CmsFree]: () => CmsFreeFlowSteps,
  [Flow.ClassroomTraining]: () => GenericFlowSteps,
  [Flow.AssetProvider]: () => GenericFlowSteps,
  [Flow.Purchase]: () => PurchaseFlowSteps,
  [Flow.Academy]: () => AcademyFlowSteps,
  [Flow.AcademyEmbedded]: () => AcademyFlowSteps,
  [Flow.Developers]: () => DeveloperFlowSteps,
  [Flow.CmsDevelopers]: () => DeveloperFlowSteps,
  [Flow.SolutionsProvider]: () => NoSurveyFlowSteps,
  [Flow.FbCrm]: () => NoSurveyFlowSteps,
  [Flow.DirectoryListing]: () => DirectoryListingFlowSteps,
  [Flow.HubspotForStartups]: () => HubSpotForStartupsFlowSteps,
  [Flow.HubSpotPartners]: () => PartnerFlowSteps,
  [Flow.Connect]: () => UserFlowsSteps,
  [Flow.StandaloneCmsPartner]: () => StandaloneCmsPartnerFlowSteps,
  [Flow.Integrations]: () => getIntegrationFlowSteps(),
  [Flow.Microapp]: () => getMicroappFlowSteps(),
  [Flow.Commerce]: () => CommerceFlowSteps,
  [Flow.EngageAI]: () => EngageAIFlowsSteps
};