import { Flow } from 'signup-constants/Flow';
import { Microapp } from 'signup-constants/Microapp';
import { ErrorKey } from '../app-active-error/ErrorKey';
import { LoadingKey } from '../app-active-loading/LoadingKey';
import { isExistingUserExistingAccount, isntExistingUserExistingAccount } from '../app/verificationTypeSelectors';
import { getGatesWithUrlOverrides } from '../auth/authUtils';
import { getFlowConfig } from '../flow-initial/getFlowConfig';
import { handleBlogSettingsError } from '../http-blog-ideas-generator/blogSettingsHandlers';
import { getBlogSettings } from '../http-blog-ideas-generator/getBlogSettings';
import { loginNewUser } from '../http-login/loginNewUser';
import { fetchGates } from '../http-survey/fetchGates';
import { handleLoginNewPortalFailure, handleLoginNewPortalSuccess } from '../http-survey/handleLoginNewPortalResponse';
import { handlePostSignupError } from '../http-survey/handlePostSignupResponse';
import { postSignup } from '../http-survey/postSignup';
import { getFlowKey } from '../routing/getFlowKey';
import { FlowKeyToPartnerType } from '../solutions-provider/FlowKeyToPartnerType';
import { handleProvisionPartnerSuccess } from '../solutions-provider/handleProvisionPartnerSuccess';
import { provisionPartner } from '../solutions-provider/provisionPartner';
import { getIsNewPortalCreated, getIsNewUser } from '../store/app/selectors';
import { redirectExistingUserAction, updateAuth } from '../store/auth/action-creators';
import { isPortalIdDefined } from '../store/auth/selectors';
import { setError } from '../store/error/action-creators';
import { isPartnerSignup } from '../store/flow/selectors';
import { isEndpointStatusSuccess } from '../store/http-responses/selectors';
import { Tracker } from '../store/tracking/action-creators';
import { isMicroapp } from '../utils/isMicroapp';
import { EndpointKey } from './EndpointKey';
import { checkIfLoggedInIfNotExistingAccount, hasAnsweredAllQuestions, isLoggedInAccountAndPortal, logInAndQuestionsCheck } from './endpointStateChecks';
import { generateInvoiceAssets } from '../http-invoice-generator/generateInvoiceAssets';
import { handleInvoiceAssetsGenerateError, handleInvoiceAssetsGenerateSuccess } from '../http-invoice-generator/invoiceHandlers';
export const EndpointsEndOfSignup = {
  [EndpointKey.LoginNewPortal]: {
    isStateOnlyTrigger: true,
    loadingKey: LoadingKey.LoginAfterSignup,
    once: true,
    stateCheck: state => getIsNewPortalCreated(state) && hasAnsweredAllQuestions(state) && isntExistingUserExistingAccount(state),
    method: loginNewUser,
    handleSuccess: (response, dispatch) => handleLoginNewPortalSuccess(dispatch),
    handleError: handleLoginNewPortalFailure
  },
  [EndpointKey.GateLookup]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: isLoggedInAccountAndPortal,
    method: fetchGates,
    handleSuccess: (response, dispatch) => {
      dispatch(updateAuth({
        gates: getGatesWithUrlOverrides(response)
      }));
      if (isMicroapp(Microapp.LandingPageCreator)) {
        dispatch(redirectExistingUserAction());
      }
    }
  },
  [EndpointKey.PostSignup]: {
    isStateOnlyTrigger: true,
    loadingKey: LoadingKey.PostSignupLoading,
    once: true,
    stateCheck: state => logInAndQuestionsCheck(state) && getFlowConfig().shouldSubmitPostSignup,
    method: postSignup,
    handleError: handlePostSignupError
  },
  [EndpointKey.TrackSurveyCompleted]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: logInAndQuestionsCheck,
    method: (state, dispatch) => new Promise(resolve => {
      dispatch(Tracker.surveyCompleted());
      dispatch(Tracker.surveyInteraction());
      resolve(true);
    })
  },
  [EndpointKey.PartnerLookup]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: state => {
      return isPartnerSignup() && isExistingUserExistingAccount(state) && isPortalIdDefined(state);
    },
    method: state => {
      const flowKey = getFlowKey();
      if (flowKey === Flow.DirectoryListing || flowKey === Flow.SolutionsProvider) {
        return provisionPartner(FlowKeyToPartnerType[flowKey], state);
      }
      return Promise.reject(new Error('Invalid Partner Flow Key'));
    },
    loadingKey: LoadingKey.PartnerExistingAccount,
    handleSuccess: handleProvisionPartnerSuccess,
    handleError: (error, dispatch) => {
      //These error keys need to be updated once we have designs for the screens
      if (error && error.status && error.status === 'HUB_ALREADY_A_PARTNER') {
        dispatch(setError(ErrorKey.AlreadyAPartner));
      } else {
        dispatch(setError(ErrorKey.PartnerExistingAccountError));
      }
    }
  },
  [EndpointKey.CheckScopesForBlogIdeasGeneratorNewUser]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: state => {
      const check = getIsNewUser(state) && logInAndQuestionsCheck(state) && isMicroapp(Microapp.BlogIdeasGenerator) && !isEndpointStatusSuccess(EndpointKey.CheckScopesForBlogIdeasGeneratorNewUser)(state);
      return check;
    },
    method: getBlogSettings,
    handleError: handleBlogSettingsError
  },
  [EndpointKey.InvoiceSignupBridge]: {
    isStateOnlyTrigger: true,
    once: true,
    stateCheck: state => {
      const check = checkIfLoggedInIfNotExistingAccount(state) && isMicroapp(Microapp.InvoiceGenerator);
      return check;
    },
    method: generateInvoiceAssets,
    handleSuccess: handleInvoiceAssetsGenerateSuccess,
    handleError: handleInvoiceAssetsGenerateError
  }
};