import styled from 'styled-components';
import { devices } from '../../styles/viewports.styles';
export const HeaderContainer = styled.div.withConfig({
  displayName: "Headerstyles__HeaderContainer",
  componentId: "sc-1tfgr0a-0"
})(["display:flex;justify-content:space-between;align-items:center;min-height:50px;"]);
export const HeaderWrapper = styled.div.withConfig({
  displayName: "Headerstyles__HeaderWrapper",
  componentId: "sc-1tfgr0a-1"
})(["flex-direction:column;display:flex;grid-column-start:2;grid-column-end:span 3;margin:1rem 0;"]);
export const Logo = styled.div.withConfig({
  displayName: "Headerstyles__Logo",
  componentId: "sc-1tfgr0a-2"
})(["order:1;margin-left:auto;@media ", "{margin-top:1rem;order:initial;margin-left:0;}"], devices.desktop);
export const AccountSelector = styled.div.withConfig({
  displayName: "Headerstyles__AccountSelector",
  componentId: "sc-1tfgr0a-3"
})(["@media ", "{margin-left:auto;}margin-bottom:", ";"], devices.desktop, props => props.displayBackLink ? '8px' : '0');