import { Step } from 'signup-constants/Step';
import { FlowStateManager } from '../flow-management/FlowStateManager';
import { setWhichOAuthSelected } from '../store/app/action-creators';
export const setFlowAfterGoogleCredentialAuthentication = response => dispatch => {
  let stepsToRemove = [];
  if (response.email) {
    stepsToRemove = [Step.LandingPage, Step.Verification, Step.CodeVerification, Step.Password, ...(response.firstName && response.lastName ? [Step.Name] : [])];
  } else {
    dispatch(setWhichOAuthSelected(null));
  }
  dispatch(FlowStateManager.removeSteps(stepsToRemove));
};