import { Data } from 'signup-constants/signupData/Data';
import { DataOptions } from '../signup-data/signupDataOptions';
import { useRecommendedHublet } from './useRecommendedHublet';
import { useHubletOverrides } from './useHubletOverrides';
export const useHublets = () => {
  const {
    recommendedHublet
  } = useRecommendedHublet();
  const {
    hubletOverrides
  } = useHubletOverrides();
  const hublets = hubletOverrides !== null && hubletOverrides !== void 0 ? hubletOverrides : DataOptions[Data.HubletSelection];
  if (!recommendedHublet) {
    return {
      hubletOptions: hublets,
      recommendedHublet: null
    };
  }
  const remainingHublets = hublets.filter(hubletOption => hubletOption !== recommendedHublet);

  // If the array hasn't changed length, then we didn't find the recommended hublet.
  // So we should just return the default array.
  const recommendedHubletNotFound = remainingHublets.length === hublets.length;
  if (recommendedHubletNotFound) {
    return {
      hubletOptions: hublets,
      recommendedHublet: null
    };
  }
  return {
    hubletOptions: [recommendedHublet, ...remainingHublets],
    recommendedHublet
  };
};