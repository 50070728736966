import { Flow } from 'signup-constants/Flow';
import { ExperimentKeys, TEST_KEY } from './ExperimentKeys';
import { enrollOnFlow } from './experimentDefaults';
import { getIsNewUser } from '../store/app/selectors';
import { getFlowKey } from '../routing/getFlowKey';
export const EXPERIMENT_ID = 'experimentId';
const SEEN_EXPERIMENTS = new Set();
const Experiments = new Map([[ExperimentKeys.ACQ0157, {
  shouldEnroll: () => true
}], [ExperimentKeys.ACQ0162, {
  shouldEnroll: () => true
}], [ExperimentKeys.ACQ0164, {
  shouldEnroll: state => {
    const isNewUser = getIsNewUser(state);
    const isCrmFlow = getFlowKey() === Flow.Crm;
    return isNewUser && isCrmFlow;
  }
}]]);
export const getExperiment = id => {
  // we want to prefer this solution over mocking getExperiment so that the
  // seen experiments tracking below continues to work and doesn't get
  // accidentally mocked
  if (id === TEST_KEY) {
    return {
      shouldEnroll: () => enrollOnFlow([Flow.Crm])
    };
  }
  SEEN_EXPERIMENTS.add(id);
  return Experiments.get(id);
};
export function getSeenExperimentsForTesting() {
  return Array.from(SEEN_EXPERIMENTS);
}