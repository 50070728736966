import { Step } from 'signup-constants/Step';
import { FlowStateManager } from '../../flow-management/FlowStateManager';
import { getHttpResponse } from '../../store/http-responses/selectors';
import { EndpointKey } from '../../http/EndpointKey';
import { getIsNewUser } from '../../store/app/selectors';
export const BranchAddHubletNewUser = {
  name: 'BranchAddHubletNewUser',
  stateCheck: state => {
    const httpResponse = getHttpResponse(state, EndpointKey.HubletChoiceNewUser);
    return getIsNewUser(state) && httpResponse && httpResponse.availableHublets && httpResponse.availableHublets.length > 1;
  },
  rebuildFlow: (flow, state, dispatch) => {
    return dispatch(FlowStateManager.addLastStepsBeforeAccountCreation(Step.HubletSelection));
  },
  processed: false,
  processedOnStep: null,
  afterData: null,
  afterStep: null
};