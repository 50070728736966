module.exports = {
  "acq-0157": {
    "identifierType": "OTHER",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variant_a"
      ]
    }
  },
  "acq-0162": {
    "identifierType": "OTHER",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variant_a"
      ]
    }
  },
  "acq-0164": {
    "identifierType": "OTHER",
    "maxAge": 0,
    "parameters": {
      "group": [
        "control",
        "variant_a"
      ]
    }
  }
};