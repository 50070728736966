import { Flow } from 'signup-constants/Flow';
import { FormType } from 'signup-ui-lego-core/constants/FormType';
import { isCodeVerificationSignup, isEmailVerificationSignup } from '../app/verificationTypeSelectors';
import { getLegalConsentOptionsForSignupRequest } from '../hubspot-forms/form-data-utils/getLegalConsentOptions';
import { getSubmitContext } from '../hubspot-forms/form-data-utils/getSubmitContext';
import { getAmplitudeEventBody } from '../hubspot-forms/getAmplitudeEventBody';
import Logger from '../lib/Logger';
import { getFlowKey } from '../routing/getFlowKey';
import { getIntegrationOrFlowKey } from '../routing/getIntegrationOrFlowKey';
import { getQueryParams } from '../routing/getQueryParams';
import { Data } from 'signup-constants/signupData/Data';
import { getQueryParamsNoSignupData } from '../signup-data/getQueryParamsNoSignupData';
import { getValidatedCompanyDomain } from '../signup-data/getValidatedCompanyDomain';
import { getRecaptchaSiteKey, getRecaptchaToken } from '../store/google-recaptcha/selectors';
import { getAccountCreatedFormSurveyData, getFlatSignupDataValueMap } from '../store/signup-data/selectors';
import { getTalonValue } from '../talon/getTalonValue';
import { getTrialName, getTrialSource } from '../trial/updateFormFieldsTrialInformation';
import { getImpactAdditionalParams } from '../utils/affiliateUtils';
import { isFlow } from '../utils/isFlow';
import { parseUtmData } from '../utm/utmHelpers';
import { getMicroappKey } from '../routing/getMicroappKey';
const LOG = Logger.getLogger('getSignupBody');
export const getSignupBody = state => {
  const talon = getTalonValue();
  const signupData = getFlatSignupDataValueMap(state);
  const flowKey = getFlowKey();
  const microapp = getMicroappKey();
  const userInformation = {
    userInformation: {
      firstName: signupData[Data.FirstName],
      lastName: signupData[Data.LastName],
      password: signupData[Data.Password]
    }
  };
  const FormsApiParams = Object.assign({
    flowKey: getIntegrationOrFlowKey(),
    signupContext: getSubmitContext(FormType.SignupCompleted),
    firstName: signupData[Data.FirstName],
    lastName: signupData[Data.LastName],
    legalConsent: getLegalConsentOptionsForSignupRequest()
  }, isFlow(Flow.Trial) && {
    trialName: getTrialName(),
    trialSource: getTrialSource()
  }, microapp && {
    microapp
  });
  const queryParams = getQueryParams();
  const queryParamsWithoutSignupData = getQueryParamsNoSignupData(queryParams, signupData);
  const recaptchaToken = getRecaptchaToken(state);
  const recaptchaSiteKey = getRecaptchaSiteKey(state);
  if (!recaptchaToken || !recaptchaSiteKey) {
    LOG.error('Missing recaptcha information', {
      extra: {
        flowKey,
        recaptchaToken,
        recaptchaSiteKey
      }
    });
  }
  const verificationToken = signupData[Data.Token];
  const verificationCode = isCodeVerificationSignup(state) && signupData[Data.CodeVerification];
  const hublet = signupData[Data.HubletSelection];
  const utms = parseUtmData(queryParams);
  const amplitudeEventBody = getAmplitudeEventBody(state);
  const additionalUrlParams = Object.assign({}, queryParamsWithoutSignupData, utms, getImpactAdditionalParams(), {
    flowPath: flowKey
  });
  const surveyData = getAccountCreatedFormSurveyData(state);
  const signupBody = Object.assign({
    isOdyssey: true,
    signupProperties: amplitudeEventBody.signupProperties,
    additionalUrlParams,
    companyName: signupData[Data.CompanyName],
    domain: getValidatedCompanyDomain(signupData[Data.CompanyDomain]),
    email: signupData[Data.Email]
  }, verificationToken && {
    verificationToken
  }, verificationCode && {
    verificationCode
  }, (isEmailVerificationSignup(state) || isCodeVerificationSignup(state)) && userInformation, {
    languageOptions: amplitudeEventBody.languageOptions,
    recaptchaToken,
    recaptchaSiteKey,
    talon,
    useNewDomainErrorMessages: true
  }, FormsApiParams, {
    surveyData,
    queryParams: Object.assign({}, queryParamsWithoutSignupData, utms)
  }, amplitudeEventBody.referrerData && {
    referrerData: amplitudeEventBody.referrerData
  }, amplitudeEventBody.utms && {
    utms: amplitudeEventBody.utms
  }, amplitudeEventBody.experimentParameters && {
    experimentParameters: amplitudeEventBody.experimentParameters
  }, hublet && {
    creationHublet: hublet
  }, {
    applicationId: amplitudeEventBody.applicationId,
    signupVerificationType: amplitudeEventBody.signupVerificationType,
    settings: []
  });
  return signupBody;
};